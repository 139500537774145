import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

import Button from 'ant-design-vue/lib/button';
import Tooltip from 'ant-design-vue/lib/tooltip';
import DatePicker from 'ant-design-vue/lib/date-picker';
import Select from 'ant-design-vue/lib/select';
import antInputDirective from 'ant-design-vue/es/_util/antInputDirective';
import _Spin2 from 'ant-design-vue/lib/spin';
import Empty from 'ant-design-vue/lib/empty';
import _Modal2 from 'ant-design-vue/lib/modal';
import Descriptions from 'ant-design-vue/lib/descriptions';
import Icon from 'ant-design-vue/lib/icon';
import VueHtml2pdf from 'vue-html2pdf'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import Vuelidate from "vuelidate";

import JsonCSV from 'vue-json-csv'
import JsonExcel from "vue-json-excel";
// Axios Mock Adapter
import '@/@fake-db/db'

import 'ant-design-vue/dist/antd.css'
import { Input, InputNumber, Popover, Progress, Table } from 'ant-design-vue'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueHtml2pdf)

// Composition API
Vue.use(VueCompositionAPI)
// Vue.component(JsonCSV.name, JsonCSV)
Vue.component('downloadCsv', JsonCSV)
Vue.component("downloadExcel", JsonExcel);
// Vue.component("ExcelComponent", JsonExcel);
// vuelidate
Vue.use(Vuelidate)
Vue.use(antInputDirective)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

require('ant-design-vue/dist/antd.css')

Vue.config.productionTip = false


Vue.component(Button.name, Button);
Vue.component(Tooltip.name, Tooltip);
Vue.component(DatePicker.name, DatePicker);
Vue.component(DatePicker.name, DatePicker);
Vue.component(DatePicker.RangePicker.name, DatePicker.RangePicker);
Vue.component(DatePicker.MonthPicker.name, DatePicker.MonthPicker);
Vue.component(DatePicker.WeekPicker.name, DatePicker.WeekPicker);
Vue.component(Select.name, Select);
Vue.component(Select.Option.name, Select.Option);
Vue.component(Select.OptGroup.name, Select.OptGroup);
Vue.component(_Spin2.name, _Spin2);
Vue.component(Empty.name, Empty);
Vue.component(_Modal2.name, _Modal2);
Vue.component(Descriptions.name, Descriptions);
Vue.component(Icon.name, Icon); 
Vue.component(Popover.name, Popover);
Vue.component(Table.name, Table);
Vue.component(Progress.name, Progress);
Vue.use(Input);
Vue.use(InputNumber);

// AN - Disable console log in prod
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
